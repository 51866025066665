import { useEffect, useState, RefObject } from 'react';

type UseIntersectionObserverProperties = Readonly<{
  ref?: RefObject<Element> | null;
  element?: Element | null | undefined;
  options?: IntersectionObserverOptions;
  callback?: (entries: IntersectionObserverEntry[]) => void;
}>;

type IntersectionObserverOptions = Readonly<{
  triggerOnce: boolean;
  threshold: number | number[];
  root?: Element;
  rootMargin?: string;
}>;

const CHECK_WINDOW = typeof window !== 'undefined';

export const useIO = ({
  ref,
  element,
  options = { triggerOnce: false, threshold: 0 },
  callback,
}: UseIntersectionObserverProperties): boolean => {
  const [inView, setInView] = useState<boolean>(false);
  const handleIntersect = (entries: IntersectionObserverEntry[]): void => {
    if (!observer) return;
    if (options.triggerOnce) {
      const hasIntersected = entries.some((e) => e.isIntersecting);
      if (hasIntersected) {
        callback?.(entries);
        observer.disconnect();
      }
      setInView(hasIntersected);
      return;
    }
    const isIntersecting = entries[entries.length - 1].isIntersecting;
    if (isIntersecting) callback?.(entries);
    setInView(isIntersecting);
  };
  const [observer] = useState(() => (CHECK_WINDOW ? new IntersectionObserver(handleIntersect, options) : undefined));
  useEffect(() => {
    if (!observer) return;
    let domElement;
    if (ref) domElement = ref.current;
    else if (element) domElement = element;
    if (domElement) observer.observe(domElement);
    return () => observer.disconnect();
  }, [ref, observer, element]);
  return inView;
};
