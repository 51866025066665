import styled from '@emotion/styled';
import Image from 'next/image';
import { useIsMobile, useIsTablet } from '@hooks/responsive/useIsMobile';
import { useMemo, useRef } from 'react';

// components
import Typography from '@components/_atoms/Typography';

// constants
import { TypoVariant } from '@constants/atoms';
import { MOBILE_MODE, TABLET_MODE } from '@constants/size';
import { gray, primary } from '@styles/Colors';
import { Keyframes } from '@constants/keyframes';

// hooks
import { useIO } from '@hooks/interaction/useIO';
import { useTranslation } from 'next-i18next';

const Content = styled.div`
  max-width: 1080px;
  margin: 0 auto;

  @media ${TABLET_MODE} {
    max-width: 688px;
  }

  @media ${MOBILE_MODE} {
    max-width: 320px;
  }
`;

const DescriptionBox = styled.div`
  padding: 0 40px;

  display: grid;
  row-gap: 8px;

  @media ${MOBILE_MODE} {
    padding: 0;
  }
`;

const HighLight = styled.span`
  color: ${primary.blue};
`;

const ImageWrapper = styled.div`
  margin-top: 40px;
  position: relative;

  &[aria-hidden='false'] {
    animation: ${Keyframes.fadeInUp} 1s 0.3s both;
  }
`;

export default function Section6() {
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();
  const { t, i18n } = useTranslation('home');
  const ko = i18n.language === 'ko';

  const ref = useRef<HTMLDivElement>(null);
  const observer = useIO({ ref, options: { triggerOnce: true, threshold: 0 } });

  const [width, height, name] = useMemo(() => {
    if (isMobile) {
      return ['320px', '944px', 'company_sm'];
    }
    if (isTablet) {
      return ['688px', '512px', 'company_md'];
    }

    return ['1080px', '368px', 'company_lg'];
  }, [isTablet, isMobile]);

  return (
    <Content>
      <DescriptionBox>
        <Typography variant={isMobile ? TypoVariant.B2 : TypoVariant.B1} color={gray.gray7}>
          {t('section6.title')}
        </Typography>
        <Typography variant={isMobile ? TypoVariant.SH1 : TypoVariant.H5} color={primary.gray}>
          {ko ? (
            <>
              이미 국내외 많은 기업들이
              <br />
              <HighLight>스틸보소</HighLight>와 거래를 하고 있어요
            </>
          ) : (
            <>
              Steelboso has already established business relationships with numerous companies, both domestically and
              internationally.
            </>
          )}
        </Typography>
      </DescriptionBox>

      <ImageWrapper ref={ref} style={{ maxWidth: width, height }} aria-hidden={!observer}>
        <Image src={`/assets/images/${name}.webp`} layout="fill" alt={name} />
      </ImageWrapper>
    </Content>
  );
}
