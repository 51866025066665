import React from 'react';
import { useMemo } from 'react';
import styled from '@emotion/styled';

// colors
import { primary, blue, gray, red, white, black } from '@styles/Colors';

// components
import Typography from '../Typography';
import Icon from '@components/_atoms/Icon';

// constants
import { IconSize, TabbarAlign, TabbarVariant } from '@constants/atoms';
import { TypoVariant } from '@constants/atoms';

type Props = {
  readonly variant?: TabbarVariant;
  readonly align?: TabbarAlign;
  readonly selectLabel?: string;
  readonly label?: string[];
  readonly onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

type CssProps = {
  align: TabbarAlign;
  borderBottom: string;
};

const TabbarWrapper = styled.div<CssProps>`
  display: flex;
  column-gap: 10px;
  justify-content: ${({ align }) => align};
  align-items: center;
  border-bottom: ${({ borderBottom }) => borderBottom};
`;

type TabType = {
  select?: boolean;
};
const Tab = styled.button<TabType>`
  background: transparent;
  border: none;
  padding: 9px 8px;
  cursor: pointer;

  /* select */
  ${(props) =>
    props.select &&
    `
    padding-bottom:5px;
    border-bottom: 4px solid ${primary.blue};
  `}
`;

const DividerTab = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
`;

const DividerIcon = styled(Icon)``;

export default function Tabbar({
  variant = TabbarVariant.DEFAULT,
  align = TabbarAlign.CENTER,
  selectLabel,
  label = [],
  onClick,
}: Props) {
  const [borderBottom, TabbarChildren, SelectColor, fontSize] = useMemo(() => {
    if (variant === TabbarVariant.DEFAULT) {
      return [`1px solid ${gray.gray2}`, Tab, primary.gray, TypoVariant.SH3];
    } else {
      return ['none', DividerTab, primary.blue, TypoVariant.B4];
    }
  }, [variant]);

  return (
    <TabbarWrapper align={align} borderBottom={borderBottom}>
      {label.map((item: string, idx: number) => (
        <React.Fragment key={item}>
          <TabbarChildren value={item} select={item === selectLabel} onClick={onClick}>
            <Typography
              variant={fontSize}
              color={item === selectLabel ? SelectColor : gray.gray6}
              style={{ pointerEvents: 'none' }}>
              {item}
            </Typography>
          </TabbarChildren>

          {/* divider tabbar icon */}
          {variant === TabbarVariant.DIVIDER && idx < label.length - 1 && (
            <DividerIcon name="divider" fill="transparent" stroke={gray.gray4} size={IconSize.SMALL} />
          )}
        </React.Fragment>
      ))}
    </TabbarWrapper>
  );
}
