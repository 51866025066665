import { BadgeColor } from '@constants/atoms';
import { blue, gray, green, red, yellow } from '@styles/Colors';
import { useMemo } from 'react';

export const useGetBadgeColor = (color: BadgeColor) => {
  const [backgroundColor, textColor] = useMemo(() => {
    switch (color) {
      case BadgeColor.Blue:
        return [blue.blue1, blue.blue3];
      case BadgeColor.Red:
        return [red.red1, red.red3];
      case BadgeColor.Yellow:
        return [yellow.yellow1, yellow.yellow4];
      case BadgeColor.Green:
        return [green.green1, green.green3];
      case BadgeColor.Gray:
        return [gray.gray1, gray.gray5];
    }
  }, [color]);
  return [backgroundColor, textColor];
};
