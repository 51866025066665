import { keyframes } from '@emotion/react';

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    transform: translate3d(0, 300px, 0);
  }
  100% {
    opacity: 1;
    trasnform: translate3d(0,0,0);
  }
`;

const RowSlider = keyframes`
  0% {
      transform: translateX(0);
  }
  50% {
      transform: translateX(-100%);
  }
  50.01%{
      transform: translateX(100%);
  }
  100%{
      transform: translateX(0);
  }
`;
const RowSliderClone = keyframes`
  0% {
      transform: translateX(0%);
  }
  100% {
      transform: translateX(-200%);
  }
`;

const ColumnSlider = keyframes`
  0% {
      transform: translateY(0);
  }
  50% {
      transform: translateY(-100%);
  }
  50.01%{
      transform: translateY(100%);
  }
  100%{
      transform: translateY(0);
  }
`;
const ColumnSliderClone = keyframes`
  0% {
      transform: translateY(0%);
  }
  100% {
      transform: translateY(-200%);
  }
`;

const slowOpacityWithScale = keyframes`
  0% {
    opacity: 0;
    transform: scale3d(.93,.93,.93);
  }
  100% {
    opacity: 1;
    transform: scale3d(1,1,1);
  }
`;

export const Keyframes = { fadeInUp, RowSlider, RowSliderClone, ColumnSlider, ColumnSliderClone, slowOpacityWithScale };
