import styled from '@emotion/styled';
import { gray, white } from '@styles/Colors';
import { Radius } from '@styles/Radius';

type Props = Styleable & {
  height?: string;
  minHeight?: string;
  children?: React.ReactNode;
  backgroundWhite?: boolean;
};

const CardWrapper = styled.div<{ backgroundWhite?: boolean }>`
  width: 100%;
  padding: 24px;
  border-radius: ${Radius.MEDIUM};
  background-color: ${gray.gray1};

  ${({ backgroundWhite }) =>
    backgroundWhite &&
    `
    border: 1px solid ${gray.gray3};
    background: ${white};
  `}
`;

export default function Card({ style, height, minHeight, children, backgroundWhite }: Props) {
  return (
    <CardWrapper style={{ height, minHeight, ...style }} backgroundWhite={backgroundWhite}>
      {children}
    </CardWrapper>
  );
}
