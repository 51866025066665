import styled from '@emotion/styled';
import Link from 'next/link';
import { useIsMobile } from '@hooks/responsive/useIsMobile';

// components
import Button from '@components/_atoms/Button';
import Typography from '@components/_atoms/Typography';

// constants
import { COMPANY_INTRO } from '@constants/routes/routes';
import { TypoVariant } from '@constants/atoms';
import { MOBILE_MODE } from '@constants/size';
import { gray, primary } from '@styles/Colors';
import { useTranslation } from 'next-i18next';
import ButtonCode from '@constants/log/buttonCode';

const Content = styled.div`
  max-width: 582px;
  margin: 0 auto;

  @media ${MOBILE_MODE} {
    max-width: 320px;
  }
`;

const DescriptionBox = styled.div`
  text-align: center;

  display: grid;
  row-gap: 8px;
`;

const HighLight = styled.span`
  color: ${primary.blue};
`;

const ButtonWrapper = styled.div`
  margin-top: 40px;

  display: flex;
  justify-content: center;
`;

export default function Section0() {
  const isMobile = useIsMobile();
  const { t, i18n } = useTranslation('home');
  const ko = i18n.language === 'ko';

  return (
    <Content>
      <DescriptionBox>
        <Typography variant={isMobile ? TypoVariant.B2 : TypoVariant.B1} color={gray.gray7}>
          {t('section0.title')}
        </Typography>
        <Typography variant={isMobile ? TypoVariant.SH1 : TypoVariant.H5} color={primary.gray}>
          {ko ? (
            <>
              다양한 데이터를 분석하여 고객님들이 간편하고 안전하게 스틸 자재를 거래하실 수 있도록 돕는
              <HighLight> 스틸 자재 거래 플랫폼</HighLight>입니다
            </>
          ) : (
            <>Steelboso provides convenient and safe platform to trade steel materials through big data analysis, </>
          )}
        </Typography>
      </DescriptionBox>
      <ButtonWrapper>
        <Link href={COMPANY_INTRO}>
          <a>
            <Button text={t('section0.button')} steelbosoId={ButtonCode.BTN_COMPANY} />
          </a>
        </Link>
      </ButtonWrapper>
    </Content>
  );
}
